@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

*, *::before, *::after {
    box-sizing: border-box;    
}
html::-webkit-scrollbar {
  display: none;
}

:root {
  --main-color: #FFBA26;
  --primary-text-color: #242423;
  --main-bg-color: #FFEBE2;
  --grey-color: #F7FCFF;
}

/* body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom);
  background-color: var(--main-bg-color);
  box-sizing: border-box;
} */

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  box-sizing: border-box;
  /* Apply background color to inset areas */
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom);
  background-clip: padding-box;
  background-color: var(--main-bg-color);
}

.pageContainer,
.listingDetails,
.category,
.explore {
  margin: 1rem;
}
@media (min-width: 1024px) {
  .pageContainer {
    margin: 3rem;
  }
}

.pageContainer{
  margin-top: 8rem;
}

.pageHeader {
  font-size: 2rem;
  font-weight: 800;
}

a {
  text-decoration: none;
  display: block;
  color: #000000;
}

button {
  outline: none;
  border: none;
}

.passwordInput,
.emailInput {
  margin-bottom: 1rem;
}

.input,
.passwordInput,
.emailInput,
.baseWordInput,
.wordDefinitionInput,
.wordExampleInput,
.textarea {
  box-shadow: rgba(0, 0, 0, 0.11);
  border: none;
  background: #ffffff;
  border-radius: 1rem;
  height: 3rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}

.profileDisplayName {
  border-radius: 1rem;
  height: 3rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-color);
}

.profileDisplayNameText {
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0;
}

.profileInput {
  margin-bottom: 1rem;
}

.inputLabel {
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.baseWordInput,
.wordDefinitionInput,
.wordExampleInput {
  border-radius: 0;
  background: transparent;
}

.addWordForm,
.wordListContainer,
.practiceWordContainer {
  border-radius: 1rem;
  background: #ffffff;
  position: relative;
}

.practiceWordContainer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
}

.practiceForm {
  display: flex;
  border-bottom: 2px solid var(--main-color);
}

.closeForm {
  position: absolute;
  top: 0;
  right: 0;
}

.practiceBaseWordInput {
    flex-grow: 1;
    background: none;
    border: none;
    font-size: 1.5rem;
}

.correct {
  border-bottom: 2px solid green;
}

.incorrect {
  border-bottom: 2px solid red;
}

.practiceSubmitButton {
  font-size: 1.5rem;
  background: transparent;
}

.practiceBaseWordInput:focus{
    outline: none;
}

.wordListContainer {
  padding-inline-start: 0;
}

.wordListItem {
  list-style-type: none;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem; */
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid var(--main-bg-color);
}

.actionBtn {
  padding: 1rem;
  width: fit-content;
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.return, 
.profile {
  font-size: 1.5rem;
}


.return {
  margin-right: auto;
  padding-left: 0;
}

.profile {
  margin-left: auto;
  padding-right: 0;
}

.logout {
  cursor: pointer;
  border-radius: 1rem;
  background-color: var(--grey-color);
}

.wordListItem:last-child {
  border-bottom: none;
}

@media (min-width: 1100px) {
  .input,
  .passwordInput,
  .emailInput,
  .baseWordInput,
  .wordDefinitionInput,
  .wordExampleInput,
  .nameInput,
  .textarea {
    padding: 0 5rem;
  }
}

.emailInput {
  background: url('./assets/svg/personIcon.svg') #ffffff 5% center no-repeat;
}

.passwordInput {
  background: url('./assets/svg/lockIcon.svg') #ffffff 5% center no-repeat;
}

@media (min-width: 1100px) {
  .passwordInput,
  .emailInput {
    background-position: 2% center;
  }
}

.passwordInputDiv {
  position: relative;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: -4%;
  right: 1%;
  padding: 1rem;
}

.signInBar,
.signUpBar,
.practiceBar,
.addWordBar {
  margin-top: 2rem;
}

.wordListBar {
  margin-top: 1rem;
}

.signInButton,
.signUpButton,
.practiceButton,
.addWordButton,
.signInText,
.signUpText,
.practiceText,
.practiceSubmitButton,
.addWordText {
  cursor: pointer;
}
@media (min-width: 1024px) {
  .signInBar,
  .signUpBar,
  .practiceBar,
  .addWordBar {
    justify-content: start;
  }
}

.signInText,
.signUpText,
.practiceText,
.addWordText,
.wordListText{
  font-size: 1.2rem;
  font-weight: 600;
}

.signInButton,
.signUpButton,
.practiceButton,
.addWordButton,
.wordListButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  background-color: var(--main-color);
}

.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 1rem 0;
  cursor: pointer;
}

.wordListButton {
  background-color: var(--grey-color);
}



.signInButton:disabled,
.signUpButton:disabled {
  background-color: var(--grey-color);
  cursor: auto;
}

.socialLogin {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}

.registerLink {
  text-decoration: underline;
  margin-top: 1rem;
  font-weight: 600;
  text-align: center;
}

.forgotPasswordLink {
  text-decoration: underline;
  width: fit-content;
  text-align: right;
  padding:0.5rem 0;
  cursor: pointer;
  margin-left: auto;
}

.backToMainLink {
  text-decoration: underline;
  width: fit-content;
  padding: 0.5rem 0;
  cursor: pointer;
  margin: auto;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.05); */
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: var(--main-color) transparent var(--main-color)transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}